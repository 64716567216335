import { CurrencyPipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";
import * as FileSaver from "file-saver";
import * as moment from "moment";
import { ReverseOrderService } from "../../actions/reverse-order/reverse-order.service";
import { AddInstructionComponent } from "../modals/add-instruction/component/add-instruction.component";
import { CreateTicketComponent } from "../modals/create-ticket/component/create-ticket.component";
import { QueryModalComponent } from "../modals/query-modal/component/query-modal.component";
import { FullScreenLoaderService } from "../modules/full-screen-loader/full-screen-loader.service";
import { RupeePaisePipe } from "../pipes/paise-to-rupee.pipe";
import { BatchService } from "./batch.service";
import { NotificationService } from "./notification.service";
import { OrdersDataService } from "./orders-data.service";
import { ProfileDataService } from "./profile-data.service";

interface GeneratePDF {
  apiUrl: string;
  body: any;
}

@Injectable({
  providedIn: "root"
})
export class OrderActionService {
  // declaration
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  fileName: string;
  rupeePaisePipe = new RupeePaisePipe();
  currencyPipe = new CurrencyPipe("en-IN");

  directPrintThreshold: number = 25;

  constructor(
    private _http: HttpClient,
    private _notification: NotificationService,
    private _fullScreenLoader: FullScreenLoaderService,
    private _dialog: MatDialog,
    private _ordersDataService: OrdersDataService,
    private _profileDataService: ProfileDataService,
    // private _router: Router,
    private _reverseOrderService: ReverseOrderService,
    private _batchService: BatchService
  ) {}

  createTicket(order = {}, openedFrom: string) {
    this._dialog.open(CreateTicketComponent, {
      panelClass: "medium-modal",
      data: { ...order, openedFrom }
    });
  }

  resetBatchServiceOrders() {
    this._batchService.batchAllOrders.next([]);
  }

  codVerification(
    orders: any,
    option = "single" || "selected",
    resetBatchOrders?: boolean
  ): void {
    this._fullScreenLoader.show();

    let orderIds = [];

    if (option === "single") {
      orderIds.push(orders.SG_ORDERID);
    } else {
      orderIds = orders.map((order) => order.SG_ORDERID);
    }

    this._http
      .post("user/vas/cod-verify/generate", { orderIds })
      .subscribe((res: any) => {
        this._fullScreenLoader.hide();
        if (res.success) {
          //to reset batch orders
          if (resetBatchOrders) {
            this.resetBatchServiceOrders();
          }
          this._notification.show(res.msg);
        }
      });
  }

  resyncOrder(orders: any, option: string, resetBatchOrders?: boolean): void {
    let body = {};

    if (option === "single") {
      body["orderIds"] = orders.SG_ORDERID;
    } else if (option === "selected") {
      body["orderIds"] = orders.map((order) => order.SG_ORDERID);
    } else if (option === "batch") {
      body["batchId"] = orders;
    }

    this._fullScreenLoader.show();
    this._http.post("user/order/resync", body).subscribe((res: any) => {
      this._fullScreenLoader.hide();
      if (res.success) {
        this._ordersDataService.refreshOrders();

        //to reset batch orders
        if (resetBatchOrders) {
          this.resetBatchServiceOrders();
        }
        this._notification.show(res.msg);
      } else {
        this._notification.show(res.msg);
      }
    });
  }

  acceptDiscrepancy(order) {
    this.confirmDialogRef = this._dialog.open(FuseConfirmDialogComponent, {
      panelClass: "small-modal"
    });

    const weightDescCharge = this.rupeePaisePipe.transform(
      order.weightDesc.newCharge - order.weightDesc.oldCharge,
      "rupee"
    );

    this.confirmDialogRef.componentInstance.confirmTitle = "Accept Discrepancy";
    this.confirmDialogRef.componentInstance.confirmMessage = `${this.currencyPipe.transform(
      weightDescCharge,
      "INR"
    )} will be deducted from your Stamp balance towards Weight Discrepancy for order number ${
      order.orderNumber
    }.`;

    this.confirmDialogRef.componentInstance.confirmButtonText = "Yes";
    this.confirmDialogRef.componentInstance.cancelButtonText = "No";

    this.confirmDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.acceptFlag(order);
      }
      this.confirmDialogRef = null;
    });
  }

  // Flag Handling
  acceptFlag(order) {
    this._fullScreenLoader.show();

    let orderId = order.SG_ORDERID;

    this._http
      .post("user/order/replyWeightDesc", { orderId, option: "yes" })
      .subscribe((res: any) => {
        this._fullScreenLoader.hide();
        if (res.success) {
          this._notification.show("Request submitted successfully.");
          this._ordersDataService.refreshOrders();
          this._profileDataService.getProfileData();
        } else {
          this._notification.show(res.msg);
        }
      });
  }

  acceptBulkDiscrepancy(ordersAwb: any) {
    this._fullScreenLoader.show();
    const body = {
      orders: ordersAwb
    };
    this._http.post("user/order/acceptWDbulk", body).subscribe((res: any) => {
      this._fullScreenLoader.hide();
      if (res.success) {
        this._ordersDataService.refreshOrders();
        this._profileDataService.getProfileData();
        this._notification.show(res?.msg);
      } else {
        this._notification.show(res.msg);
      }
    });
  }

  callActionAPIForNDROrders(apiUrl: string, body: any) {
    this._fullScreenLoader.show();
    this._http.post(apiUrl, body).subscribe((res: any) => {
      this._fullScreenLoader.hide();
      if (res.success) {
        this._ordersDataService.refreshOrders();
        this._notification.show(res.msg);
      } else {
        this._notification.show(res.msg);
      }
    });
  }

  queryFlag(order) {
    let orderId = order.SG_ORDERID;

    this._dialog.open(QueryModalComponent, {
      panelClass: "medium-modal",
      data: {
        ids: orderId
      }
    });
  }

  requestReattempt(order) {
    const _dialogRef = this._dialog.open(AddInstructionComponent, {
      panelClass: "small-modal",
      data: {
        awb: order.awb
      }
    });

    _dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._ordersDataService.refreshOrders();
      }
    });
  }

  requestRTO(order) {
    this.confirmDialogRef = this._dialog.open(FuseConfirmDialogComponent, {
      panelClass: "small-modal"
    });

    this.confirmDialogRef.componentInstance.confirmTitle = "Request RTO";
    this.confirmDialogRef.componentInstance.confirmMessage =
      "Are you sure you want the shipment Returned back?";
    this.confirmDialogRef.componentInstance.confirmButtonText = "Yes";
    this.confirmDialogRef.componentInstance.cancelButtonText = "No";

    this.confirmDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.submitRtoRequest(order);
      }
      this.confirmDialogRef = null;
    });
  }

  submitRtoRequest(order) {
    this._fullScreenLoader.show();
    this._http
      .post("user/order/ndrAction", { option: "RTO", awb: order.awb })
      .subscribe((res: any) => {
        this._fullScreenLoader.hide();
        if (res.success) {
          this._notification.show("Request submitted successfully.");
          this._ordersDataService.refreshOrders();
        } else {
          this._notification.show(res.msg);
        }
      });
  }

  generatePickup(orders, option?: string, resetBatchOrders?: boolean): void {
    this._fullScreenLoader.show();

    let orderId: any[] = [];
    if (option === "single") {
      orderId.push(orders.SG_ORDERID);
    } else {
      orderId = orders.map((order) => order.SG_ORDERID);
    }

    this._http
      .post("user/order/pickupOrder", { orderId })
      .subscribe((res: any) => {
        this._fullScreenLoader.hide();
        if (res.success) {
          this._ordersDataService.refreshOrders();

          //to reset batch orders
          if (resetBatchOrders) {
            this.resetBatchServiceOrders();
          }
          this._notification.show(res.msg);
        } else {
          this._notification.show(res.msg);
        }
      });
  }

  confirmReverseOrder(order): void {
    this.confirmDialogRef = this._dialog.open(FuseConfirmDialogComponent, {
      panelClass: "small-modal"
    });

    this.confirmDialogRef.componentInstance.confirmTitle = "Reverse Order";

    this.confirmDialogRef.componentInstance.confirmMessage =
      "Are you sure you want to reverse this order?";

    this.confirmDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._reverseOrderService.reverseOrder(order);
      }

      this.confirmDialogRef = null;
    });
  }

  cancelOrder(orders, option?: string, resetBatchOrders?: boolean): void {
    let orderId: any[] = [];
    let orderNumbers: any[] = [];

    if (option === "single") {
      orderId.push(orders.SG_ORDERID);
    } else {
      orderId = orders.map((order) => order.SG_ORDERID);
      orderNumbers = orders.map((order) => order.orderNumber);
    }

    this.confirmDialogRef = this._dialog.open(FuseConfirmDialogComponent, {
      panelClass: "small-modal"
    });

    this.confirmDialogRef.componentInstance.confirmTitle = "Cancel Order";

    this.confirmDialogRef.componentInstance.confirmMessage = `Are you sure you want to cancel ${
      option === "single" ? `order?` : `these orders?`
    }`;

    this.confirmDialogRef.componentInstance.confirmSummary = `${
      option === "single"
        ? `${orders.orderNumber}`
        : `${orderNumbers.join(", ")}`
    }`;

    this.confirmDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.callApi("user/order/cancelOrder", { orderId }, resetBatchOrders);
      }

      this.confirmDialogRef = null;
    });
  }

  deleteOrders(orders, option?: string, resetBatchOrders?: boolean): void {
    let orderId: any[] = [];
    let orderNumbers: any[] = [];

    if (option === "single") {
      orderId.push(orders.SG_ORDERID);
    } else {
      orderId = orders.map((order) => order.SG_ORDERID);
      orderNumbers = orders.map((order) => order.orderNumber);
    }

    this.confirmDialogRef = this._dialog.open(FuseConfirmDialogComponent, {
      panelClass: "small-modal"
    });

    this.confirmDialogRef.componentInstance.confirmTitle = "Delete Order";

    this.confirmDialogRef.componentInstance.confirmMessage = `Are you sure you want to delete ${
      option === "single" ? `order?` : `these orders?`
    }`;

    this.confirmDialogRef.componentInstance.confirmSummary = `${
      option === "single"
        ? `${orders.orderNumber}`
        : `${orderNumbers.join(", ")}`
    }`;

    this.confirmDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.callApi("user/order/deleteOrders", { orderId }, resetBatchOrders);
      }

      this.confirmDialogRef = null;
    });
  }

  callApi(apiUrl: string, body: any, resetBatchOrders?: boolean): void {
    this._fullScreenLoader.show();

    this._http.post(apiUrl, body).subscribe((res: any) => {
      this._fullScreenLoader.hide();
      if (res.success) {
        const isDeleteAction = apiUrl.includes("delete");
        this._ordersDataService.refreshOrders(
          isDeleteAction
            ? { type: "delete", count: body?.orderId?.length }
            : { type: "cancel" }
        );

        //to reset batch orders
        if (resetBatchOrders) {
          this.resetBatchServiceOrders();
        }
        this._notification.show(res.msg);
      } else {
        this._notification.show(res.msg);
      }
    });
  }

  downloadPDF(obj: GeneratePDF): void {
    const { apiUrl, body } = obj;

    // tell backend guys to add logo from backend because in front-end it increases one never ending subscription
    this._http.post(apiUrl, body).subscribe((res: any) => {
      this._fullScreenLoader.hide();

      if (res.success) {
        // tell backend guys to change res.labels.data to res.buffer.data for labels

        if (res.direct) {
          const buffer = this.fileName.includes("label")
            ? res.labels.data
            : res.buffer.data;
          const blob = new Blob([new Uint8Array(buffer)], {
            type: "application/pdf"
          });
          const file = new File([blob], this.fileName, {
            type: "application/pdf;"
          });
          FileSaver.saveAs(file);
        }
        this._notification.show(res.msg);
      } else {
        this._notification.show(res.msg);
      }
    });
  }

  generateAll(orders, option?: string, bulkResponse: any = {}): void {
    const apiUrl: string = "user/order/singleButtonPickup";
    const type = "All";
    const { batchName, date, bookedCount, batchId } = bulkResponse;
    const { orderNumber, createdAt } = orders;
    const body: any = {};
    if (option === "single") {
      body.awbArray = [orders.awb];
      body.count = body.awbArray.length;
    } else if (option === "bulkOrders") {
      body.batchId = batchId;
      body.count = bookedCount;
      body.type = "current";
    } else {
      body.awbArray = orders.map((order) => order.awb);
      body.count = body.awbArray.length;
    }

    this.mediaGenerationRequest("All", apiUrl, body);
  }

  generateLabel(orders, option?: string, bulkResponse: any = {}): void {
    const apiUrl: string = "user/order/print/label";
    const type = "label";
    const { batchName, date, bookedCount, batchId } = bulkResponse;
    const { orderNumber, createdAt } = orders;
    const body: any = {};

    if (option === "single") {
      body.awbArray = [orders.awb];
      body.count = body.awbArray.length;

      // for setting fileName
      this.setFileName(orderNumber, type, createdAt);
    } else if (option === "bulkOrders") {
      body.batchId = batchId;
      body.count = bookedCount;
      body.type = "current";

      // for setting fileName
      this.setFileName(batchName || "bulk-orders", type, date);
    } else {
      body.awbArray = orders.map((order) => order.awb);
      body.count = body.awbArray.length;

      // for setting fileName
      this.setFileName("selected-orders", type);
    }

    if (body.count > this.directPrintThreshold) {
      this.mediaGenerationRequest("Label", apiUrl, body);
    } else {
      this._fullScreenLoader.show();
      this.downloadPDF({ apiUrl, body });
    }
  }

  // generateReverseLabel(order): void {
  //   this._fullScreenLoader.show();

  //   this.downloadPDF({
  //     apiUrl: "user/order/reverseLabel",
  //     body: { SGORDERID: order.SG_ORDERID },
  //     fileName: "label"
  //   });
  // }

  generateManifest(orders, option?: string, bulkResponse: any = {}): void {
    const apiUrl: string = "user/order/print/manifest";
    const type = "manifest";
    const { batchName, date, bookedCount, batchId } = bulkResponse;
    const { orderNumber, createdAt } = orders;
    const body: any = {};

    if (option === "single") {
      body.awbArray = [orders.awb];
      body.count = body.awbArray.length;

      // for setting fileName
      this.setFileName(orderNumber, type, createdAt);
    } else if (option === "bulkOrders") {
      body.batchId = batchId;
      body.count = bookedCount;
      body.type = "current";

      // for setting fileName
      this.setFileName(batchName || "bulk-orders", type, date);
    } else {
      body.awbArray = orders.map((order) => order.awb);
      body.count = body.awbArray.length;

      // for setting fileName
      this.setFileName("selected-orders", type);
    }

    if (body.count > this.directPrintThreshold) {
      this.mediaGenerationRequest("Manifest", apiUrl, body);
    } else {
      this._fullScreenLoader.show();
      this.downloadPDF({ apiUrl, body });
    }
  }

  generateShippingInvoice(
    orders,
    option?: string,
    bulkResponse: any = {}
  ): void {
    const apiUrl: string = "user/order/print/invoice";
    const type = "shipping-invoice";
    const { batchName, date, bookedCount, batchId } = bulkResponse;
    const { orderNumber, createdAt } = orders;
    const body: any = {};

    if (option === "single") {
      body.awbArray = [orders.awb];
      body.count = body.awbArray.length;

      // for setting fileName
      this.setFileName(orderNumber, type, createdAt);
    } else if (option === "bulkOrders") {
      body.batchId = batchId;
      body.count = bookedCount;
      body.type = "current";

      // for setting fileName
      this.setFileName(batchName || "bulk-orders", type, date);
    } else {
      body.awbArray = orders.map((order) => order.awb);
      body.count = body.awbArray.length;

      // for setting fileName
      this.setFileName("selected-orders", type);
    }

    if (body.count > this.directPrintThreshold) {
      this.mediaGenerationRequest("Invoice", apiUrl, body);
    } else {
      this._fullScreenLoader.show();
      this.downloadPDF({ apiUrl, body });
    }
  }

  setFileName(
    name: string,
    type: string,
    date: string = new Date().toISOString()
  ) {
    const formatDate = moment(date).format("DD-MM-YY");
    this.fileName = `${formatDate}-${name}-${type}.pdf`;
  }

  mediaGenerationRequest(mediaType: string, apiUrl: string, body: any) {
    let dialogMessage: string;
    if (mediaType == "All") {
      dialogMessage = `It  looks like you are trying to generate pickup and media for selected order(s) at once. This might take a while. We will email and notify you with the link of generated media as soon as its done.`;
    } else {
      dialogMessage = `It looks like you are trying to generate media for many orders at once. This might take a while. We will email and notify you with the link of generated media as soon as its done.`;
    }
    const { email: userEmail } =
      this._profileDataService?.profileData?.getValue()?.profile || {};

    this.confirmDialogRef = this._dialog.open(FuseConfirmDialogComponent, {
      panelClass: "small-modal"
    });

    this.confirmDialogRef.componentInstance.confirmButtonText = "Okay";
    this.confirmDialogRef.componentInstance.confirmTitle = `Generate ${mediaType} (${body.count})`;
    this.confirmDialogRef.componentInstance.confirmMessage = dialogMessage;

    this.confirmDialogRef.componentInstance.confirmSummary = userEmail
      ? `Your registered email address is: ${userEmail}`
      : "";

    this.confirmDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._fullScreenLoader.show();
        this.downloadPDF({
          apiUrl,
          body
        });
      }

      this.confirmDialogRef = null;
    });
  }
}
