// @ts-nocheck
import { shippigoConfig } from "tenants/shippigo/config";

export const environment = {
  production: false,
  apiEndPoint: "https://shippigo-dev.emizainc.com/",
  mode: "staging",
  logErr: false,
  consoleErr: true,
  appVersion: require("../../package.json").version,
  tenant: shippigoConfig,
};
